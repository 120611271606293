"use client";

import { Bounce } from "react-activity";

export function Loading() {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <Bounce speed={0.5} size={10} color="#efefef" />
    </div>
  );
}
