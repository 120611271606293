"use client";

import React, { useEffect, useState } from "react";
import Image from "next/legacy/image";
import { Card, CardBody, Spacer, Skeleton } from "@nextui-org/react";
import { motion } from "framer-motion";
import Link from "next/link";
export type CardWithThumbnailProps = {
  id: string;
  title?: string;
  description?: string;
  thumbnailUrl?: string;
  primaryUrl: string;
};

export function CardWithThumbnail({
  id,
  title,
  description,
  thumbnailUrl,
  primaryUrl,
}: CardWithThumbnailProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      <Card
        className="w-full border-1 border-divider"
        as={Link}
        href={`${primaryUrl}`}
      >
        <CardBody className="">
          <div className=" aspect-video w-full rounded-r-md object-cover">
            <Skeleton
              className="aspect-video w-full rounded-r-md object-cover"
              style={{ height: (480 / 16) * 9, maxWidth: 480 }}
              isLoaded={thumbnailUrl !== undefined && thumbnailUrl !== ""}
            >
              {thumbnailUrl && (
                <Image
                  alt="Card image"
                  src={thumbnailUrl}
                  width={480}
                  height={(480 / 16) * 9}
                  priority={true}
                  className="rounded-md object-cover"
                />
              )}
            </Skeleton>
          </div>

          <Spacer y={4} />
          <div className="flex min-h-20 flex-col gap-1 px-2">
            <Skeleton isLoaded={title !== undefined}>
              <h4 className="line-clamp-1 text-xl pb-1">{title}</h4>
              <p className="text-md text-default-500">{description}</p>
            </Skeleton>
          </div>
        </CardBody>
      </Card>
    </motion.div>
  );
}
