export function Search() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.6667 24.6667L20.6667 20.6667M20.6667 20.6667L16.6667 16.6667M20.6667 20.6667L16.6667 24.6667M20.6667 20.6667L24.6667 24.6667M26.6667 12.6667C26.6667 19.4444 19.4444 26.6667 12.6667 26.6667C5.88896 26.6667 0.888962 19.4444 0.888962 12.6667C0.888962 5.88896 5.88896 0.888962 12.6667 0.888962C19.4444 0.888962 26.6667 5.88896 26.6667 12.6667Z"
        stroke="#FFC72C"
        strokeWidth="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
