export class System {
  private environment: string;
  private debug: boolean;

  constructor(options?: { environment?: string; debug?: boolean }) {
    this.environment = options?.environment || process.env.NODE_ENV || 'development';
    this.debug = options?.debug || false;
  }

  // Environment checks
  isDevelopment = () => this.environment === 'development';
  isStaging = () => this.environment === 'staging';
  isProduction = () => this.environment === 'production';

  // Logging
  log = {
    info: (message: string, ...args: any[]) => {
      if (this.debug || this.isDevelopment()) {
        console.log(message, ...args);
      }
    },
    error: (message: string, error?: Error) => {
      console.error(message, error);
    },
    warn: (message: string, ...args: any[]) => {
      console.warn(message, ...args);
    },
    debug: (message: string, ...args: any[]) => {
      if (this.debug) {
        console.debug(message, ...args);
      }
    },
  };

  // Performance monitoring
  performance = {
    start: (label: string) => {
      if (this.debug) {
        console.time(label);
      }
    },
    end: (label: string) => {
      if (this.debug) {
        console.timeEnd(label);
      }
    },
  };
}
