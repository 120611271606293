import { type AnthropicProvider } from "@ai-sdk/anthropic";
import { GroqProvider } from "@ai-sdk/groq";
import { OpenAIProvider } from "@ai-sdk/openai";
import { MistralProvider } from "@ai-sdk/mistral";
import { CohereProvider } from "@ai-sdk/cohere";
import { chromeai } from "chrome-ai";
import { XaiProvider } from "@ai-sdk/xai";
import type { ModelConfig } from "./types";
import { OllamaProvider } from "ollama-ai-provider";

export const MODEL_NAMES = [
  "claude-3-opus-20240229",
  "claude-3-sonnet-20240229",
  "llama-3.1-8b-instant",
  "mistral-medium",
  "qwen2p5-coder-32b-instruct",
  "chrome-ai",
  "grok-beta",
  "llama3.2:latest",
] as const;
export enum Models {
  "claude-3-opus-20240229",
  "claude-3-sonnet-20240229",
  "llama-3.1-8b-instant",
  "mistral-medium",
  "qwen2p5-coder-32b-instruct",
  "chrome-ai",
  "grok-beta",
  "llama3.2:latest",
}
export type Model = keyof Models;

export type ModelProviders =
  | AnthropicProvider["languageModel"]
  | GroqProvider["languageModel"]
  | OpenAIProvider["languageModel"]
  | MistralProvider["languageModel"]
  | CohereProvider["languageModel"]
  | XaiProvider["languageModel"]
  | OllamaProvider
  | "chromeai";

export const defaultModels = {
  anthropic: ["claude-3-5-sonnet-20241022"],
  groq: ["mixtral-8x7b", "llama-3.1-8b-instant", "llava-1-3-14b-v1"],
  openai: ["gpt-4-turbo", "gpt-4-vision"],
  mistral: ["mistral-large", "mistral-medium"],
  cohere: ["command", "command-light"],
  fireworks: ["qwen2p5-coder-32b-instruct"],
  chromeai: ["chrome-ai"],
  xai: ["grok-beta"],
} as const;

// Define base capabilities for each provider
const baseCapabilities = {
  anthropic: {
    multimodal: true,
    embeddings: false,
    visionAnalysis: true,
    streaming: true,
    functionCalling: true,
  },
  groq: {
    multimodal: false,
    embeddings: true,
    visionAnalysis: false,
    streaming: true,
    functionCalling: true,
  },
  mistral: {
    multimodal: false,
    embeddings: true,
    visionAnalysis: false,
    streaming: true,
    functionCalling: true,
  },
  fireworks: {
    multimodal: false,
    embeddings: true,
    visionAnalysis: false,
    streaming: true,
    functionCalling: true,
  },
  chromeai: {
    multimodal: false,
    embeddings: false,
    visionAnalysis: false,
    streaming: true,
    functionCalling: false,
  },
};

// Define provider capabilities interface
export interface ProviderCapabilities {
  multimodal: boolean;
  embeddings: boolean;
  visionAnalysis: boolean;
  streaming: boolean;
  functionCalling: boolean;
  contextWindow: number;
}

// Define model configurations
export const MODEL_CONFIGS = new Map<string, ModelConfig>([
  // Anthropic Models
  [
    "claude-3-opus-20240229",
    {
      name: "claude-3-opus-20240229",
      capabilities: {
        ...baseCapabilities.anthropic,
        contextWindow: 200000,
      },
      maxTokens: 200000,
      inputCostPer1k: 0.015,
      outputCostPer1k: 0.075,
    },
  ],
  [
    "claude-3-sonnet-20240229",
    {
      name: "claude-3-sonnet-20240229",
      capabilities: {
        ...baseCapabilities.anthropic,
        contextWindow: 200000,
      },
      maxTokens: 200000,
      inputCostPer1k: 0.003,
      outputCostPer1k: 0.015,
    },
  ],

  // Groq Models
  [
    "llama-3.1-8b-instant",
    {
      name: "llama-3.1-8b-instant",
      capabilities: {
        ...baseCapabilities.groq,
        contextWindow: 4096,
      },
      maxTokens: 4096,
      inputCostPer1k: 0.0001,
      outputCostPer1k: 0.0002,
    },
  ],

  // Mistral Models
  [
    "mistral-medium",
    {
      name: "mistral-medium",
      capabilities: {
        ...baseCapabilities.mistral,
        contextWindow: 32768,
      },
      maxTokens: 32768,
      inputCostPer1k: 0.002,
      outputCostPer1k: 0.006,
    },
  ],

  // Fireworks Models
  [
    "qwen2p5-coder-32b-instruct",
    {
      name: "qwen2p5-coder-32b-instruct",
      capabilities: {
        ...baseCapabilities.fireworks,
        contextWindow: 16384,
      },
      maxTokens: 16384,
      inputCostPer1k: 0.0005,
      outputCostPer1k: 0.0015,
    },
  ],

  // ChromeAI Models
  [
    "chrome-ai",
    {
      name: "chrome-ai",
      capabilities: {
        ...baseCapabilities.chromeai,
        contextWindow: 16384,
      },
      maxTokens: 16384,
      inputCostPer1k: 0.0005,
      outputCostPer1k: 0.0015,
    },
  ],
]);

// Helper function to get model family
export function getModelFamily(model: string): string {
  if (model.includes("claude")) return "anthropic";
  if (model.includes("llama") || model.includes("gemma")) return "groq";
  if (model.includes("mistral") || model.includes("mixtral")) return "mistral";
  if (model.includes("qwen")) return "fireworks";
  if (model.includes("chrome")) return "chromeai";
  return "unknown";
}
