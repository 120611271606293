// Define template IDs for type safety and autocompletion
export const TemplateIds = {
  // Browser Automation
  GENERATE_SEQUENCE_STEPS: 'generate_sequence_steps',
  CORRECT_BROWSER_INSTRUCTIONS: 'correct_browser_instructions',
  SEQUENCE_STEPS_GENERATION: 'sequence_steps_generation',

  // Selectors
  SUGGEST_SELECTORS: 'suggest_selectors',
  ANALYZE_SELECTOR_ERRORS: 'analyze_selector_errors',

  // Audience Analysis
  IDENTIFY_AUDIENCE_WEBSITE: 'identify_audience_website',

  // Business Analysis
  ANALYZE_COMPETITORS: 'analyze_competitors',

  // Visual Analysis
  ANALYZE_INTERACTIVE_ELEMENTS: 'analyze_interactive_elements',

  // Code Generation
  GENERATE_CODE: 'generate_code',
  CORRECT_CODE: 'correct_code',
} as const;

export type TemplateId = (typeof TemplateIds)[keyof typeof TemplateIds];

export enum PromptId {
  SEQUENCE_STEPS_GENERATION = 'sequence_steps_generation',
  CODE_GENERATION = 'code_generation',
  CODE_CORRECTION = 'code_correction',
  SUGGEST_SELECTORS = 'suggest_selectors',
  ANALYZE_SELECTOR_ERRORS = 'analyze_selector_errors',
  IDENTIFY_AUDIENCE_WEBSITE = 'identify_audience_website',
  ANALYZE_COMPETITORS = 'analyze_competitors',
  ANALYZE_INTERACTIVE_ELEMENTS = 'analyze_interactive_elements',
}

export const PROMPT_VARIABLES = {
  browserBestPractices: `
1. Always wait for elements before interacting
2. Use specific, reliable selectors
3. Include proper error handling
4. Validate each step's success
5. Consider network conditions
6. Handle dynamic content properly
7. Use appropriate timeouts
8. Include fallback mechanisms
9. Log important state changes
10. Clean up resources after completion`,

  selectorStrategies: `
1. data-test attributes (most reliable)
2. unique IDs
3. specific attributes (name, role, etc.)
4. class combinations
5. element hierarchy
6. relative positioning
7. text content (least reliable)`,

  commonErrors: `
1. Element not found
2. Timeout exceeded
3. Navigation failed
4. Network error
5. Selector mismatch
6. Frame navigation
7. JavaScript execution
8. Resource loading
9. Authentication issues
10. State synchronization`,

  validationStrategies: `
1. Element visibility check
2. Content verification
3. State confirmation
4. URL validation
5. Element property check
6. Network request completion
7. DOM state verification
8. Error absence confirmation
9. Expected changes check
10. Resource loading completion`,
} as const;
