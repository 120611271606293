import { generateText, LanguageModelV1, type Message as CoreMessage } from "ai";
import { createAnthropic } from "@ai-sdk/anthropic";
import { createOpenAI } from "@ai-sdk/openai";
import { createOllama } from "ollama-ai-provider";
// import { HfInference } from '@huggingface/inference';
// import { chromeai } from 'chrome-ai';
import { Logger } from "@/utils/logger";
import { createXai } from "@ai-sdk/xai";
import type { AIConfig, AIPromptResult, AIInstructionOptions, Message } from "./types";
import * as crypto from "crypto";
import { TemplateIds, TemplateId } from "./consts";
import { createGroq } from "@ai-sdk/groq";
import { createCohere } from "@ai-sdk/cohere";
import { Model, Models } from "./models";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { Database, Enums, Json } from "@/types/supabase";

export type AIProvider = Enums<"ai_provider">;

export interface StuntDoubleAIConfig extends AIConfig {
  supabase: SupabaseClient<Database>;
}

export class StuntDoubleAI {
  private config: Required<Omit<AIConfig, "supabase">>;
  private supabase: SupabaseClient<Database>;

  constructor(config: AIConfig) {
    if (!config.anthropicApiKey && !config.openaiApiKey && !config.apiKey) {
      throw new Error(
        "At least one API key is required (anthropicApiKey, openaiApiKey, or apiKey)",
      );
    }

    this.supabase = config.supabase;

    // Remove supabase from config before spreading
    const { supabase, ...restConfig } = config;

    this.config = {
      apiKey: restConfig.apiKey || "",
      baseURL: restConfig.baseURL || "",
      model: restConfig.model || Models[1],
      defaultModel: restConfig.defaultModel || "grok-beta",
      provider: restConfig.provider || "xai",
      options: restConfig.options || {},
      anthropicApiKey: restConfig.anthropicApiKey || "",
      openaiApiKey: restConfig.openaiApiKey || "",
      xaiApiKey: restConfig.xaiApiKey || "",
      mistralApiKey: restConfig.mistralApiKey || "",
      groqApiKey: restConfig.groqApiKey || "",
      fireworksApiKey: restConfig.fireworksApiKey || "",
      cohereApiKey: restConfig.cohereApiKey || "",
    } as Required<Omit<AIConfig, "supabase">>;
  }

  private async logInteraction(params: {
    provider: AIProvider;
    model: string;
    prompt: string;
    response?: any;
    tokens?: number;
    duration: number;
    success: boolean;
    error?: string;
    metadata?: Json | undefined;
    userId?: string;
    workspaceId?: string;
  }) {
    try {
      await this.supabase
        .schema("infrastructure")
        .from("ai_interactions")
        .insert({
          provider: params.provider,
          model: params.model,
          prompt: params.prompt,
          tokens: params.tokens,
          duration: params.duration,
          response: JSON.stringify(params.response),
          error: params.error,
          metadata: params.metadata,
          user_id: params.userId,
          workspace_id: params.workspaceId,
        });
    } catch (error) {
      Logger.warn("Interaction", { params });
      Logger.warn("Failed to log AI interaction:", { error });
    }
  }

  public async template(
    templateId: TemplateId,
    templateData: { description: string; context: string },
    options: { model: string; temperature: number; maxTokens: number },
  ): Promise<AIPromptResult> {
    const templatePrompt = this.getTemplatePrompt(templateId, templateData);

    return this.prompt(templatePrompt, {
      model: options.model,
      temperature: options.temperature,
      maxTokens: options.maxTokens,
    });
  }

  private getTemplatePrompt(
    templateId: TemplateId,
    data: { description: string; context: string },
  ): string {
    switch (templateId) {
      case TemplateIds.SEQUENCE_STEPS_GENERATION:
        return `
          Generate a sequence of automation steps based on this description:
          ${data.description}
          
          Context:
          ${data.context}
          
          Return the steps as a JSON array of instructions.
        `;
      // Add other template cases as needed
      default:
        throw new Error(`Unknown template ID: ${templateId}`);
    }
  }

  private getClient() {
    // Determine provider based on model
    const modelProvider = this.getProviderForModel(this.config.model);
    const provider = this.config.provider || modelProvider;

    const getApiKey = (provider: AIProvider): string => {
      const key = this.config[`${provider}ApiKey`] || this.config.apiKey;
      if (!key) {
        throw new Error(`No API key found for provider: ${provider}`);
      }
      return key;
    };

    const apiKey = getApiKey(provider as AIProvider);
    if (!apiKey) {
      throw new Error(`API key is required for provider: ${provider}`);
    }

    switch (provider) {
      case "anthropic":
        return createAnthropic({ apiKey });
      case "openai":
        return createOpenAI({
          apiKey,
          baseURL: this.config.baseURL || "https://api.openai.com/v1",
        });
      case "fireworks":
        return createOpenAI({
          apiKey,
          baseURL: "https://api.fireworks.ai/inference/v1",
        });

      case "groq":
        return createGroq({ apiKey });

      case "cohere":
        return createCohere({ apiKey });

      case "xai":
        return createXai({
          apiKey,
          headers: {
            "x-api-key": apiKey,
            "x-model": this.config.model, // Add model to headers for XAI
          },
        });
      case "custom": {
        return createOllama;
      }

      default:
        throw new Error(`Unsupported AI provider: ${this.config.provider}`);
    }
  }

  private getProviderForModel(model: Model): AIProvider {
    // Map models to their providers
    if (model === Models[1]) return "xai";
    // Add other model->provider mappings

    return (this.config.provider as AIProvider) || "xai"; // Default to configured provider
  }

  public async chat(messages: Message[], options?: AIInstructionOptions): Promise<AIPromptResult> {
    const startTime = Date.now();
    try {
      const model = options?.model || this.config.model;
      const provider = this.getProviderForModel(model as Model);

      const response = await generateText({
        model: model as unknown as LanguageModelV1,
        messages: messages.map((msg) => ({
          role: msg.role,
          content: msg.content,
          ...(msg.name && { name: msg.name }),
          ...(msg.function_call && { function_call: msg.function_call }),
        })) as CoreMessage[],
        temperature: options?.temperature || this.config.options.temperature || 0.7,
        maxTokens: options?.maxTokens || this.config.options.maxTokens,
        tools: options?.functions
          ? Object.fromEntries(
              options.functions.map((fn) => [
                fn.name,
                {
                  name: fn.name,
                  description: fn.description,
                  parameters: fn.parameters,
                },
              ]),
            )
          : undefined,
        toolChoice: options?.functionCall
          ? typeof options.functionCall === "string"
            ? (options.functionCall as "auto" | "none")
            : { type: "tool", toolName: options.functionCall.name }
          : undefined,
        headers: {
          "x-api-key": this.config[`${provider}ApiKey`] || this.config.apiKey,
          "x-model": model, // Add model to headers
        },
      });

      const duration = Date.now() - startTime;

      // Log successful interaction
      await this.logInteraction({
        provider,
        model: model as string,
        prompt: messages.map((m) => m.content).join("\n"),
        response: response.text,
        tokens: response.usage?.totalTokens,
        duration,
        success: true,
        metadata: options ? JSON.stringify(options) : undefined,
      });

      return {
        success: true,
        data: response.text,
        metadata: {
          model,
          tokens: response.usage?.totalTokens,
          duration,
        },
      };
    } catch (error) {
      const duration = Date.now() - startTime;

      // Log failed interaction
      await this.logInteraction({
        provider: this.getProviderForModel(
          (options?.model as Model) || (this.config.model as Model),
        ),
        model: options?.model || (this.config.model as string),
        prompt: messages.map((m) => m.content).join("\n"),
        duration,
        success: false,
        error: error instanceof Error ? error.message : "Unknown error",
        metadata: options ? JSON.stringify(options) : undefined,
      });

      Logger.error("AI chat error:", error);
      return {
        success: false,
        error: error instanceof Error ? error.message : "Unknown error",
        metadata: {
          model: options?.model || (this.config.model as Model),
          duration,
        },
      };
    }
  }

  private extractJsonFromText(text: string): unknown {
    try {
      // Case 1: Try parsing as direct JSON first
      return JSON.parse(text);
    } catch {
      try {
        // Case 2: Look for code blocks
        const codeBlockMatch = text.match(/```(?:json)?\n?(.*?)\n?```/s);
        if (codeBlockMatch && codeBlockMatch[1]) {
          return JSON.parse(codeBlockMatch[1]);
        }

        // Case 3: Look for stringified JSON patterns
        const jsonStringMatch = text.match(/({[\s\S]*}|\[[\s\S]*\])/);
        if (jsonStringMatch && jsonStringMatch[1]) {
          return JSON.parse(jsonStringMatch[1]);
        }
      } catch {
        // If all parsing attempts fail, throw the original text
        throw new Error(`Failed to parse JSON from: ${text}`);
      }
    }
  }

  public async generateText({
    prompt,
    model,
    provider = "xai",
    systemPrompt,
    json_mode,
    options,
  }: {
    prompt: string;
    model?: string;
    provider?: AIProvider;
    systemPrompt?: string;
    json_mode?: boolean;
    options?: AIInstructionOptions;
  }) {
    try {
      const startTime = Date.now();
      let client;
      if (provider === "xai") {
        client = createXai({ apiKey: this.config.xaiApiKey });
      } else if (provider === "openai") {
        client = createOpenAI({ apiKey: this.config.openaiApiKey });
      } else if (provider === "anthropic") {
        client = createAnthropic({ apiKey: this.config.anthropicApiKey });
      } else {
        throw new Error(`Unsupported AI provider: ${provider}`);
      }

      if (!client || !prompt) {
        throw new Error("No client or provider found");
      }

      const response = await generateText({
        model: client.languageModel(model),
        prompt,
        system: `${systemPrompt}. You are an expert in web automation. ${json_mode ? "Return the response as a JSON object with no other text." : ""}`,
      });

      if (json_mode) {
        try {
          const parsedJson = this.extractJsonFromText(response.text);

          // Optional: Validate with schema if needed
          // const validatedData = JsonResponseSchema.parse(parsedJson);
          // Log successful interaction
          try {
            this.logInteraction({
              provider,
              model: model as string,
              prompt,
              success: true,
              response: JSON.parse(JSON.stringify(parsedJson)),
              tokens: response.usage.totalTokens,
              metadata: JSON.parse(JSON.stringify(response.experimental_providerMetadata)),
              duration: Date.now() - startTime,
            });
          } catch (error) {
            Logger.error("Failed to log AI interaction:", error);
          }
          return {
            success: true,
            data: parsedJson as JSON,
            metadata: {
              model,
            },
          };
        } catch (parseError) {
          Logger.error("JSON parsing error:", parseError);
          try {
            this.logInteraction({
              provider,
              model: model as string,
              prompt,
              duration: Date.now() - startTime,
              success: true,
              error:
                parseError instanceof Error ? parseError.message : "Failed to parse JSON response",
              response: JSON.parse(JSON.stringify(response.text)),
            });
          } catch (error) {
            Logger.error("Failed to log AI interaction:", error);
          }
          try {
            this.logInteraction({
              provider,
              model: model as string,
              prompt,
              duration: Date.now() - startTime,
              success: true,
              response: JSON.parse(JSON.stringify(response.text)),
            });
          } catch (error) {
            Logger.error("Failed to log AI interaction:", error);
          }

          return {
            success: false,
            error:
              parseError instanceof Error ? parseError.message : "Failed to parse JSON response",
            metadata: {
              model,
              rawResponse: response.text, // Include raw response for debugging
            },
          };
        }
      }

      return {
        success: true,
        data: response.text,
        metadata: {
          model,
        },
      };
    } catch (error) {
      Logger.error("AI generateText error:", error);
      return {
        success: false,
        error: error instanceof Error ? error.message : "Unknown error",
      };
    }
  }

  public async prompt(text: string, options?: AIInstructionOptions): Promise<AIPromptResult> {
    return this.chat(
      [
        {
          role: "user",
          content: text,
          id: crypto.randomUUID(),
        },
      ],
      options,
    );
  }
}

export type { AIPromptResult };
