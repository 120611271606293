"use client";

import { ClientWrapper } from "../wrapper";
import { Button as NextUIButton } from "@nextui-org/button";
import type { ButtonProps as NextUIButtonProps } from "@nextui-org/button";

export interface ButtonProps extends NextUIButtonProps {}

export function Button(props: ButtonProps) {
  return (
    <ClientWrapper>
      <NextUIButton {...props} />
    </ClientWrapper>
  );
}

Button.displayName = "Button";
