"use client";
import { Card, CardBody } from "@nextui-org/react";
import React from "react";

export interface KeyValueProps {
  key: string;
  label: string;
  value: string;
}

export const KeyValueItems = ({ items }: { items: KeyValueProps[] }) => {
  const ItemsRender = items.map((item, index) => {
    return (
      <div key={item.key} className="flex flex-col mb-2 flex-gap-0">
        <div className="text-xs text-foreground-300">{item.label}</div>
        <div className="text-sm text-foreground-600">{item.value}</div>
      </div>
    );
  });
  return <div>{ItemsRender}</div>;
};

export const KeyValueCard = ({ items }: { items: KeyValueProps[] }) => {
  return (
    <Card className="w-full">
      <CardBody>
        <KeyValueItems items={items} />
      </CardBody>
    </Card>
  );
};
