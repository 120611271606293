import type { SDKConfig } from '../StuntDouble';

export class Config {
  private config: SDKConfig;

  constructor(config: SDKConfig) {
    this.config = config;
  }

  get<T extends keyof SDKConfig>(key: T): SDKConfig[T] {
    return this.config[key];
  }

  set<T extends keyof SDKConfig>(key: T, value: SDKConfig[T]): void {
    this.config[key] = value;
  }

  getAll(): SDKConfig {
    return this.config;
  }

  merge(newConfig: Partial<SDKConfig>): void {
    this.config = {
      ...this.config,
      ...newConfig,
    };
  }
}
