"use client";

import CodeEditorComponent from "./code-editor";
import type { CodeEditorProps as CodeEditorPropsType } from "./code-editor";

export interface CodeEditorProps extends CodeEditorPropsType {}

export function CodeEditor(props: CodeEditorProps) {
  return (
    <>
      <CodeEditorComponent {...props} />
    </>
  );
}
