"use client";

import Link from "next/link";
import { cn } from "@nextui-org/react";

export interface CardItemProps {
  icon: React.ReactNode;
  title: string;
  href: string;
  description?: string;
  cardClassName?: string;
}

export function CardItem({
  icon,
  title,
  href,
  description,
  cardClassName,
}: CardItemProps) {
  return (
    <Link href={href} className="block w-full">
      <div
        className={cn(
          "w-full transition-all duration-200 hover:scale-[1.02]",
          "rounded-lg  border-default-200 hover:bg-content2",
          "cursor-pointer",
          cardClassName
        )}
      >
        <div className="flex items-center gap-2 p-4">
          <div className="flex-shrink-0">{icon}</div>
          <div className="flex flex-col flex-grow gap-1">
            <h4 className=" text-md text-foreground/90">{title}</h4>
            {description && (
              <p className="text-tiny text-foreground/60">{description}</p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
