"use client";

import { Input } from "@nextui-org/react";

export interface DateTimePickerProps {
  label?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
}

export function DateTimePicker({
  label = "Schedule",
  value,
  onChange,
  minDate = new Date(),
}: DateTimePickerProps) {
  const formatDateTime = (date: Date) => {
    return date.toISOString().slice(0, 16);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value ? new Date(e.target.value) : null;
    onChange(newDate);
  };

  return (
    <Input
      variant="underlined"
      type="datetime-local"
      value={value ? formatDateTime(value) : ""}
      onChange={handleChange}
      min={formatDateTime(minDate)}
      className="max-w-xs"
    />
  );
}
