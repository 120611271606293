"use client";

import { Card, CardBody } from "@nextui-org/card";

export interface StatsDisplayProps {
  totalUsers: number;
  customers: number;
  prospectInvestors: number;
  consideringInvestors: number;
  waitlisted: number;
}

export function StatsDisplay({
  totalUsers,
  customers,
  prospectInvestors,
  consideringInvestors,
  waitlisted,
}: StatsDisplayProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-6">
      <Card>
        <CardBody>
          <p className="text-sm text-default-500">Total Users</p>
          <p className="text-xl">{totalUsers}</p>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <p className="text-sm text-default-500">Waitlisted</p>
          <p className="text-xl">{waitlisted}</p>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <p className="text-sm text-default-500">Active Customers</p>
          <p className="text-xl">{customers}</p>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <p className="text-sm text-default-500">Prospect Investors</p>
          <p className="text-xl">{prospectInvestors}</p>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <p className="text-sm text-default-500">Considering Investors</p>
          <p className="text-xl">{consideringInvestors}</p>
        </CardBody>
      </Card>
    </div>
  );
}
