"use client";

import { Button } from "@nextui-org/button";
import { motion } from "framer-motion";
import { useEffect } from "react";
// import type { ToastPropTypes } from "@stunt-double/sdk";

export interface ToastType {
  onClose: () => void;
  duration: number;
  description: string;
  title: string;
  type: "success" | "error" | "info";
}

export interface ToastProps {
  onClose: () => void;
  duration: number;
  description: string;
  title: string;
  type: "success" | "error" | "info";
}

export function Toast(props: ToastProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onClose();
    }, props.duration);

    return () => clearTimeout(timer);
  }, [props.onClose, props.duration]);

  const color =
    props.type === "success"
      ? "border-[0.5px] border-success-200"
      : props.type === "error"
        ? "border-[0.5px] border-warning-200"
        : props.type === "info"
          ? "border-[0.5px] border-default-200"
          : "border-[0.5px]";

  return (
    <motion.div
      initial={{ opacity: 0, y: -50, x: "100%" }}
      animate={{ opacity: 1, y: 0, x: 0 }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="fixed right-4 top-4 z-50 max-w-md">
        <div
          className={`rounded-lg bg-black p-4 ${color} flex items-center justify-between gap-2 text-white shadow-lg`}
        >
          <div className="flex flex-col">
            <p className="text-tiny text-default-400">{props.title}</p>
            <p className="text-sm text-default-800">{props.description}</p>
          </div>
          <Button isIconOnly variant="light" onPress={props.onClose} className="text-white">
            ✕
          </Button>
        </div>
      </div>
    </motion.div>
  );
}
