export const VALID_CATEGORIES: Category[] = [
  "browser",
  "ai",
  "identity",
  "communication",
  "human",
  "integration",
  "data",
  "utility",
  "event",
  "reference",
] as const;

export type ActionType =
  | "browser"
  | "ai"
  | "identity"
  | "communication"
  | "human"
  | "integration"
  | "data"
  | "utility"
  | "event"
  | "reference";

// Convert string literals to const arrays for better type safety
export const CATEGORIES = [
  "browser",
  "ai",
  "identity",
  "communication",
  "human",
  "integration",
  "data",
  "utility",
  "event",
  "reference",
] as const;
export type Category = (typeof CATEGORIES)[number];

export const BROWSER_METHODS = [
  // Navigation
  "goto", // Navigate to URL with waitUntil and timeout options

  // Element Interaction
  "click", // Click element with button, modifiers, and clickCount options
  "hover", // Hover over element with timeout
  "fill", // Fill form field with value
  "type", // Type text with delay option
  "press", // Press keyboard key with modifiers
  "select", // Select option in dropdown/select element
  "focus", // Focus on element
  "blur", // Remove focus from element

  // Mouse Actions
  "dragAndDrop", // Drag and drop with source/target selectors

  // Keyboard
  "keyDown", // Press and hold key with modifiers
  "keyUp", // Release key with modifiers

  // Waiting
  "waitForElement", // Wait for element with visibility/hidden options
  "waitForInterval", // Wait for interval

  // Scrolling
  "scrollToElement", // Scroll element into view
  "scrollToBottom", // Scroll to bottom of page
  "scrollToTop", // Scroll to top of page
  "scrollBy", // Scroll by amount

  // Evaluation
  "eval", // Evaluate JavaScript in page context

  // Screenshots
  // 'screenshot', // Take screenshot with options
  "context", // Use context to get/set/update/delete/merge/clear/snapshot/restore/validate
  // Configuration
  "setViewport", // Set viewport dimensions
] as const;
export type BrowserMethodType = (typeof BROWSER_METHODS)[number];

// TODO link to the templates
export const AI_METHODS = [
  "generate_sequence_steps", // Generate a sequence with a prompt
  "generate_text", // Generate text with prompt
  "use_template", // Use a template with variables
  "evaluate", // Evaluate text with prompt
  "summarize", // Summarize text
  "translate", // Translate text to another language
  "meta_prompt", // Meta prompt with prompt
  "chat", // Chat completion with messages
  "complete", // Text completion with prompt
  "use_function", // Function calling
  "vision", // Image understanding
] as const;
export type AIMethodType = (typeof AI_METHODS)[number];

export const AI_PROVIDERS = [
  "openai", // OpenAI API
  "anthropic", // Anthropic/Claude
  "fireworks", // Fireworks.ai (OpenAI compatible)
  "mistral", // Mistral
  "groq", // Groq
  "cohere", // Cohere
  "xai", // X.AI
  "custom", // Custom OpenAI-compatible
] as const;
export type AIProvider = (typeof AI_PROVIDERS)[number];

export const IDENTITY_METHODS = [
  "login",
  "logout",
  "register",
  "verify",
  "resetPassword",
  "updateProfile",
  "generateToken",
  "revokeToken",
  "checkSession",
] as const;
export type IdentityMethodType = (typeof IDENTITY_METHODS)[number];

export const NOTIFICATION_METHODS = [
  "sendEmail",
  // 'sendSMS',
  "sendPush",
  "sendSlack",
  // 'sendDiscord',
  "sendWebhook",
] as const;
export type NotificationMethodType = (typeof NOTIFICATION_METHODS)[number];

export const HUMAN_METHODS = [
  "requestVerification",
  "requestInput",
  "requestApproval",
  "requestReview",
  "requestCaptcha",
] as const;
export type HumanMethodType = (typeof HUMAN_METHODS)[number];

export const RETRIEVAL_METHODS = [
  "queryVector",
  "similaritySearch",
  "semanticSearch",
  "hybridSearch",
  "fetchDocument",
  "updateIndex",
] as const;
export type RetrievalMethodType = (typeof RETRIEVAL_METHODS)[number];

// Add to existing action types
export const CONTEXT_METHODS = [
  "get",
  "set",
  "update",
  "delete",
  "merge",
  "clear",
  "snapshot",
  "restore",
  "validate",
] as const;
export type ContextMethodType = (typeof CONTEXT_METHODS)[number];

// Add context scope type
export type ContextScope = "step" | "sequence" | "workspace" | "global";

// Add context value types
export type ContextValueType =
  | "string"
  | "number"
  | "boolean"
  | "object"
  | "array"
  | "null"
  | "undefined"
  | "binary"
  | "date";

export interface ActionValidation {
  required?: string[];
  conditional?: {
    selector?: (action: { action: string; [key: string]: unknown }) => boolean;
    [key: string]: ((action: { action: string; [key: string]: unknown }) => boolean) | undefined;
  };
}

// First, let's define the specific action types
export type ActionMethodType =
  | BrowserMethodType
  | AIMethodType
  | IdentityMethodType
  | NotificationMethodType
  | HumanMethodType
  | RetrievalMethodType
  | ContextMethodType;

// Then update the ACTION_VALIDATIONS to match the new type
export const ACTION_VALIDATIONS: Record<ActionType, ActionValidation> = {
  browser: {
    required: ["action"],
    conditional: {
      selector: (action: { action: string; [key: string]: unknown }) => action.action !== "goto",
    },
  },
  ai: {
    required: ["model"],
  },
  identity: {
    required: ["credentials"],
  },
  communication: {
    required: ["recipients", "content.body"],
  },
  human: {
    required: ["prompt"],
  },
  data: {
    required: ["query", "index"],
  },
  utility: {
    required: ["key", "scope"],
  },
  integration: {
    required: ["service"],
  },
  event: {
    required: ["eventType"],
  },
  reference: {
    required: ["referenceId"],
  },
} as const;

export type RunStatus = "pending" | "running" | "completed" | "failed" | "cancelled" | "timeout";

export const INTEGRATION_METHODS = [
  // API Integration
  "connect", // Establish connection
  "disconnect", // Close connection
  "authenticate", // Authenticate with service
  "subscribe", // Subscribe to service events
  "unsubscribe", // Unsubscribe from service

  // Data Operations
  "sync", // Sync data between systems
  "import", // Import data from service
  "export", // Export data to service
  "transform", // Transform data between formats

  // Service Management
  "configure", // Configure service settings
  "status", // Check service status
  "health", // Check service health
  "metrics", // Get service metrics

  // Webhook Management
  "registerWebhook", // Register webhook endpoint
  "removeWebhook", // Remove webhook endpoint
  "processWebhook", // Process webhook payload

  // Error Handling
  "retry", // Retry failed operation
  "rollback", // Rollback changes
  "validate", // Validate integration
] as const;
export type IntegrationMethodType = (typeof INTEGRATION_METHODS)[number];

export const EVENT_METHODS = [
  // Sequence Control
  "trigger", // Trigger another sequence
  "parallel", // Run sequences in parallel
  "fork", // Fork execution path
  "join", // Join parallel paths

  // Step Events
  "onStart", // Before step execution
  "onComplete", // After step completion
  "onError", // On step error
  "onTimeout", // On step timeout

  // Sequence Events
  "onSequenceStart", // Before sequence starts
  "onSequenceComplete", // After sequence completes
  "onSequenceError", // On sequence error
  "onSequenceTimeout", // On sequence timeout

  // Flow Control
  "wait", // Wait for condition
  "skip", // Skip next steps
  "retry", // Retry current step
  "abort", // Abort sequence

  // Custom Events
  "emit", // Emit custom event
  "on", // Listen for custom event
  "once", // Listen once for event
  "off", // Remove event listener
] as const;
export type EventMethodType = (typeof EVENT_METHODS)[number];

export const REFERENCE_METHODS = [
  // Reference Management
  "create", // Create reference
  "resolve", // Resolve reference
  "update", // Update reference
  "delete", // Delete reference

  // Reference Querying
  "lookup", // Lookup reference
  "search", // Search references
  "validate", // Validate reference
  "exists", // Check existence

  // Reference Relations
  "link", // Link references
  "unlink", // Unlink references
  "merge", // Merge references
  "split", // Split reference

  // Reference Versioning
  "version", // Version reference
  "checkout", // Checkout version
  "revert", // Revert to version
  "history", // Get version history

  // Reference Metadata
  "tag", // Tag reference
  "untag", // Remove tag
  "annotate", // Add annotation
  "describe", // Get description
] as const;
export type ReferenceMethodType = (typeof REFERENCE_METHODS)[number];

// Update CategoryMethodMap to include new method types
export type CategoryMethodMap = {
  web: BrowserMethodType;
  ai: AIMethodType;
  identity: IdentityMethodType;
  communication: NotificationMethodType;
  human: HumanMethodType;
  data: RetrievalMethodType;
  utility: ContextMethodType;
  integration: IntegrationMethodType;
  event: EventMethodType;
  reference: ReferenceMethodType;
};
