"use client";

import { useState } from "react";
import { Button } from "@nextui-org/button";
import { Code } from "@nextui-org/code";

interface CopyableCodeBlockProps {
  code: string;
  language: string;
}

export function CopyableCodeBlock({ code, language }: CopyableCodeBlockProps) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="relative w-full flex flex-row align-middle">
      <Code className="w-full p-3">{code}</Code>
      <Button
        size="sm"
        onClick={copyToClipboard}
        className=" absolute top-2 right-1"
        variant="light"
      >
        {isCopied ? "Copied!" : "Copy"}
      </Button>
    </div>
  );
}
