export const BROWSER_METHOD_DESCRIPTIONS = {
  goto: {
    description: 'Navigate to a URL',
    params: {
      url: 'The URL to navigate to',
      options: {
        waitUntil:
          "Wait until condition ('load', 'domcontentloaded', 'networkidle0', 'networkidle2')",
        timeout: 'Maximum navigation time in milliseconds',
      },
    },
  },
  click: {
    description: 'Click on an element matching the selector',
    params: {
      selector: 'CSS selector for the element to click',
      options: {
        button: "Mouse button to use ('left', 'right', 'middle')",
        clickCount: 'Number of clicks',
        modifiers: "Keyboard modifiers to hold ('Alt', 'Control', 'Meta', 'Shift')",
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  hover: {
    description: 'Hover over an element',
    params: {
      selector: 'CSS selector for the element to hover over',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  fill: {
    description: 'Fill a form field with text',
    params: {
      selector: 'CSS selector for the input field',
      value: 'Text to fill in the field',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  type: {
    description: 'Type text into an element',
    params: {
      selector: 'CSS selector for the element',
      text: 'Text to type',
      options: {
        delay: 'Delay between keystrokes in milliseconds',
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  press: {
    description: 'Press a keyboard key',
    params: {
      key: "Key to press (e.g., 'Enter', 'Tab', 'A')",
      options: {
        modifiers: "Keyboard modifiers to hold ('Alt', 'Control', 'Meta', 'Shift')",
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  select: {
    description: 'Select options in a <select> element',
    params: {
      selector: 'CSS selector for the select element',
      value: 'Value to select',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  focus: {
    description: 'Focus on an element',
    params: {
      selector: 'CSS selector for the element to focus',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  blur: {
    description: 'Remove focus from an element',
    params: {
      selector: 'CSS selector for the element to blur',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  dragAndDrop: {
    description: 'Drag and drop an element to a target',
    params: {
      sourceSelector: 'CSS selector for the element to drag',
      targetSelector: 'CSS selector for the drop target',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  keyDown: {
    description: 'Press and hold a keyboard key',
    params: {
      key: "Key to press (e.g., 'Control', 'Shift')",
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  keyUp: {
    description: 'Release a keyboard key',
    params: {
      key: "Key to release (e.g., 'Control', 'Shift')",
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  waitForElement: {
    description: 'Wait for an element to appear in the page',
    params: {
      selector: 'CSS selector to wait for',
      options: {
        visible: 'Wait for element to be visible',
        hidden: 'Wait for element to be hidden',
        timeout: 'Maximum wait time in milliseconds',
      },
    },
  },
  scrollToElement: {
    description: 'Scroll element into view',
    params: {
      selector: 'CSS selector for the element to scroll to',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  eval: {
    description: 'Evaluate JavaScript code in the page context',
    params: {
      expression: 'JavaScript code to evaluate',
      options: {
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  screenshot: {
    description: 'Take a screenshot',
    params: {
      options: {
        path: 'Path to save the screenshot',
        fullPage: 'Whether to take a screenshot of the full scrollable page',
        timeout: 'Maximum operation time in milliseconds',
      },
    },
  },
  setViewport: {
    description: 'Set the viewport size',
    params: {
      width: 'Viewport width in pixels',
      height: 'Viewport height in pixels',
      options: {
        deviceScaleFactor: 'Device scale factor (default: 1)',
        isMobile: 'Whether the meta viewport tag should be taken into account',
        hasTouch: 'Specifies if viewport supports touch events',
        isLandscape: 'Specifies if viewport is in landscape mode',
      },
    },
  },
} as const;

export const COMMON_OPTIONS = {
  timeout: 'Maximum time to wait for an operation in milliseconds',
  waitUntil:
    "Navigation wait condition ('load', 'domcontentloaded', 'networkidle0', 'networkidle2')",
  waitFor: 'Wait for element to be present before acting',
  typeDelay: 'Delay between keystrokes when typing',
  submitAfterFill: 'Whether to submit a form after filling',
  returnValue: 'Whether to return a value from evaluation',
} as const;

export const SELECTOR_TIPS = {
  description: 'Guide for writing effective CSS selectors',
  examples: {
    id: '#element-id',
    class: '.class-name',
    attribute: "[data-test='value']",
    nthChild: 'div:nth-child(2)',
    contains: "div:contains('text')",
    multiple: "div.class-name[data-test='value']",
    nested: 'div.parent > div.child',
  },
  bestPractices: [
    'Use data-test attributes for test automation',
    'Prefer specific selectors over generic ones',
    'Avoid selectors that depend on text content',
    'Consider element hierarchy for uniqueness',
  ],
} as const;
