import { SupabaseClient } from '@supabase/supabase-js';
import type { Database, Tables } from '../../types/supabase';

export class DatabaseService {
  constructor(private supabase: SupabaseClient<Database>) {}

  // Auth methods
  async getUser() {
    return this.supabase.auth.getUser();
  }

  async signOut() {
    return this.supabase.auth.signOut();
  }

  async signIn({ email, password }: { email: string; password: string }) {
    return this.supabase.auth.signInWithPassword({ email, password });
  }

  async signUp({ email, password }: { email: string; password: string }) {
    return this.supabase.auth.signUp({ email, password });
  }

  // Teams
  teams = {
    get: async ({ id }: { id: string }) => {
      const { data, error } = await this.supabase.from('teams').select('*').eq('id', id).single();
      return { data, error };
    },

    create: async ({ name }: { name: string }) => {
      const { data, error } = await this.supabase
        .from('teams')
        .insert({ display_name: name })
        .select()
        .single();
      return { data, error };
    },

    update: async ({ id, data: updateData }: { id: string; data: Partial<Tables<'teams'>> }) => {
      const { data, error } = await this.supabase
        .from('teams')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();
      return { data, error };
    },

    delete: async ({ id }: { id: string }) => {
      const { error } = await this.supabase.from('teams').delete().eq('id', id);
      return { error };
    },
  };

  // Workspaces
  workspaces = {
    get: async ({ id }: { id: string }) => {
      const { data, error } = await this.supabase
        .from('workspaces')
        .select('*')
        .eq('id', id)
        .single();
      return { data, error };
    },

    list: async () => {
      const { data, error } = await this.supabase.from('workspaces').select('*');
      return { data, error };
    },

    create: async ({ name, team_id, url }: { name: string; team_id: string; url: string }) => {
      const { data, error } = await this.supabase
        .from('workspaces')
        .insert({ name, team_id, url })
        .select()
        .single();
      return { data, error };
    },

    update: async ({
      id,
      data: updateData,
    }: {
      id: string;
      data: Partial<Tables<'workspaces'>>;
    }) => {
      const { data, error } = await this.supabase
        .from('workspaces')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();
      return { data, error };
    },
  };

  // Sequences
  sequences = {
    get: async ({ id }: { id: string }) => {
      const { data, error } = await this.supabase
        .from('tenant_sequences')
        .select('*')
        .eq('id', id)
        .single();
      return { data, error };
    },

    list: async ({ workspace_id }: { workspace_id: string }) => {
      const { data, error } = await this.supabase
        .from('tenant_sequences')
        .select('*')
        .eq('workspace_id', workspace_id);
      return { data, error };
    },

    create: async ({
      tenant_id,
      name,
      description,
      workspace_id,
    }: {
      tenant_id: string;
      name: string;
      description?: string;
      workspace_id: string;
    }) => {
      const { data, error } = await this.supabase
        .from('tenant_sequences')
        .insert({ tenant_id, name, description, workspace_id, version: 1 })
        .select()
        .single();
      return { data, error };
    },

    update: async ({
      id,
      data: updateData,
    }: {
      id: string;
      data: Partial<Tables<'tenant_sequences'>>;
    }) => {
      const { data, error } = await this.supabase
        .from('tenant_sequences')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();
      return { data, error };
    },
  };

  // Generic query builder
  from<T extends keyof Database['public']['Tables']>(table: T) {
    return this.supabase.from(table);
  }

  // Schema-specific query builder
  schema(schema: keyof Database) {
    return this.supabase.schema(schema);
  }
}

export type { Database, Tables };
