"use client";

import type { SVGProps } from "react";

export function LinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.913 1.714A1 1 0 0 1 5.872 1h4.406a1 1 0 0 1 .936 1.351L10.22 5h2.03a.75.75 0 0 1 .586 1.217L11.577 7.8l-.235-.47a2 2 0 0 0-.11-.185a3 3 0 0 0-.203-.262L11.731 6H9.5a2.5 2.5 0 0 0-.5-.5a.5.5 0 0 1 .031-.176L10.278 2H5.872L4.084 8h.966c.14.688.562 1.273 1.14 1.63l-1.178 4.039a.24.24 0 0 0 .007.174a.26.26 0 0 0 .118.118c.057.03.116.037.164.029a.24.24 0 0 0 .147-.095l3.428-4.307q.09-.06.177-.128l.105.21l.009.017l.35.7l-3.287 4.13c-.854 1.073-2.562.188-2.178-1.128L5.332 9H3.75a.75.75 0 0 1-.72-.964zM7.5 6a1.5 1.5 0 0 1 1.411.99c.147.001.308.009.456.036a1.58 1.58 0 0 1 1.08.75l.983 1.965a.57.57 0 0 0 .384.25c.091.016.174.02.268.019a1.5 1.5 0 1 1 .007 1a3 3 0 0 1-.456-.036a1.58 1.58 0 0 1-1.08-.75L9.57 8.259a.57.57 0 0 0-.384-.25a1.3 1.3 0 0 0-.268-.019A1.5 1.5 0 1 1 7.5 6M8 7.5a.5.5 0 1 0-1 0a.5.5 0 0 0 1 0m6 3a.5.5 0 1 0-1 0a.5.5 0 0 0 1 0"
      ></path>
    </svg>
  );
}
