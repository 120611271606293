import { type AIPromptResult, type AIInstructionOptions } from '../ai/types';
import type { IStuntDouble } from '../StuntDouble';
import { TemplateId, TemplateIds } from '../ai/consts';
import { SequenceStep } from '@/index';
interface PerformOptions {
  projectId?: string;
  sessionId?: string;
  modelName?: string;
  temperature?: number;
  maxTokens?: number;
}

export class Perform {
  private options: Required<PerformOptions>;
  private readonly sdk: IStuntDouble;

  constructor(sdk: IStuntDouble, options: PerformOptions = {}) {
    this.options = {
      projectId: options.projectId || '',
      sessionId: options.sessionId || '',
      modelName: options.modelName || 'qwen2p5-coder-32b-instruct',
      temperature: options.temperature || 0.7,
      maxTokens: options.maxTokens || 2000,
    };
    this.sdk = sdk;
  }

  // Sequence execution
  sequence = async (instructions: string[]): Promise<void> => {
    for (const instruction of instructions) {
      await this.execute(instruction);
    }
  };

  // Browser automation
  browser = async (actions: SequenceStep[]): Promise<void> => {
    if (!this.options.projectId || !this.options.sessionId) {
      throw new Error('Browser automation requires projectId and sessionId');
    }

    // Implementation will connect to browserbase session
    throw new Error('Browser automation not implemented');
  };

  // AI completion with proper integration
  aiCompletion = async (
    prompt: string,
    options?: AIInstructionOptions,
  ): Promise<AIPromptResult> => {
    const aiOptions = {
      model: options?.model || this.options.modelName,
      temperature: options?.temperature || this.options.temperature,
      maxTokens: options?.maxTokens || this.options.maxTokens,
      ...options,
    };

    return this.sdk.ai.prompt(prompt, aiOptions);
  };

  // Generate automation sequence from natural language
  generateSequence = async (
    description: string,
    context?: Record<string, unknown>,
  ): Promise<SequenceStep[]> => {
    const result = await this.sdk.ai.template(
      TemplateIds.SEQUENCE_STEPS_GENERATION,
      {
        description,
        context: JSON.stringify(context || {}),
      },
      {
        model: 'qwen2p5-coder-32b-instruct',
        temperature: 0.2, // Lower temperature for more deterministic outputs
        maxTokens: 4000, // Increased for complex sequences
      },
    );

    if (!result || !result.data) {
      throw new Error('Failed to generate sequence');
    }

    const instructions =
      typeof result.data === 'object' &&
      result.data !== null &&
      'instructions' in result.data &&
      Array.isArray((result.data as { instructions: unknown[] }).instructions)
        ? (result.data as { instructions: SequenceStep[] }).instructions
        : [];

    return instructions;
  };

  // Private helper method for sequence execution
  private async execute(instruction: string): Promise<void> {
    const sequence = await this.generateSequence(instruction);
    await this.browser(sequence as SequenceStep[]);
  }
}
