// Helper to check environment safely
// import process from 'node:process';

import type { AIAction } from "@/core/sequence/model";

// import console from 'node:console';
const isNode =
  typeof process === "object" &&
  typeof process?.versions === "object" &&
  typeof process?.versions?.node === "string";

// const isBrowser = typeof window !== 'undefined';

// Helper to check environment mode
export const getEnvironmentMode = () => {
  if (isNode) {
    return process.env.NODE_ENV || "production";
  }
  return "production";
};

export class Logger {
  static info(message: string) {
    console.log(`[StuntDouble AI] ${message}`);
  }

  static error(message: string, error: unknown) {
    // Always log errors regardless of environment
    console.error(`[StuntDouble AI] ${message}`);
  }

  static warn(message: string, p0: any) {
    console.warn(`[StuntDouble AI] ${message}`);
  }

  static debug(message: string, p0: { action: AIAction }) {
    console.debug(`[StuntDouble AI] ${message}`);
  }
}
