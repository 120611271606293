import { SupabaseClient } from '@supabase/supabase-js';
import type { Database } from '../../types/supabase';

export class Storage {
  constructor(private supabase: SupabaseClient<Database>) {}

  async upload(
    path: string,
    file: File | string,
    options?: {
      bucket?: string;
      contentType?: string;
      upsert?: boolean;
    },
  ) {
    const bucket = options?.bucket || 'public';
    const { data, error } = await this.supabase.storage.from(bucket).upload(path, file, {
      contentType: options?.contentType,
      upsert: options?.upsert ?? false,
    });
    return { data, error };
  }

  async download(path: string, options?: { bucket?: string }) {
    const bucket = options?.bucket || 'public';
    const { data, error } = await this.supabase.storage.from(bucket).download(path);
    return { data, error };
  }

  async list(prefix?: string, options?: { bucket?: string }) {
    const bucket = options?.bucket || 'public';
    const { data, error } = await this.supabase.storage.from(bucket).list(prefix);
    return { data, error };
  }

  async remove(paths: string | string[], options?: { bucket?: string }) {
    const bucket = options?.bucket || 'public';
    const { data, error } = await this.supabase.storage
      .from(bucket)
      .remove(Array.isArray(paths) ? paths : [paths]);
    return { data, error };
  }

  getPublicUrl(path: string, options?: { bucket?: string }) {
    const bucket = options?.bucket || 'public';
    return this.supabase.storage.from(bucket).getPublicUrl(path);
  }
}
