"use client";

import { useRef, useEffect, useState } from "react";
import { Tooltip } from "@nextui-org/tooltip";
import { cn } from "@nextui-org/theme";
import Editor, { type OnMount } from "@monaco-editor/react";
import type * as Monaco from "monaco-editor/esm/vs/editor/editor.api";

// Import SDK types at compile time
import { BROWSER_METHODS } from "@stunt-double/sdk";
import { Button } from "@nextui-org/button";

const methods = BROWSER_METHODS.join(" | ");
declare global {
  interface Window {
    __MODULE_EXPORTS__?: { sequence: unknown; steps: unknown[] };
  }
}

export interface FileData {
  name: string;
  content: string;
  language: string;
}

export interface TypeDefinition {
  path: string;
  types: unknown;
}

export interface CodeEditorProps {
  label: string;
  files?: FileData[];
  initialValue?: string;
  onChange: (value: string, fileName?: string) => void;
  onSave: (value: string, fileName?: string) => void;
  onExports?: (code: string) => void;
  language: string;
  placeholder?: string;
  helpText?: string;
  tooltipContent?: string;
  height?: string;
  types?: TypeDefinition[];
}

// Add a safe code evaluation helper
function safeEvaluateCode(code: string): {
  sequence?: unknown;
  steps?: unknown[];
} {
  try {
    // Create a safe evaluation context
    const context = {
      exports: {},
      sequence: undefined,
      steps: undefined,
    };

    // Use Function constructor instead of eval/require
    const fn = new Function("exports", code);
    fn(context.exports);

    // Extract sequence and steps from exports
    return {
      sequence: (context.exports as any)?.sequence,
      steps: (context.exports as any)?.steps,
    };
  } catch (error) {
    console.error("Failed to evaluate code:", error);
    return {};
  }
}

export default function CodeEditorComponent({
  label,
  files,
  initialValue = "",
  onChange,
  onSave,
  onExports,
  language = "typescript",
  placeholder,
  helpText,
  tooltipContent,
  height = "200px",
  types,
}: CodeEditorProps) {
  const editorRef = useRef<Monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoRef = useRef<typeof Monaco | null>(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isPlaceholder, setIsPlaceholder] = useState(
    !initialValue && (!files || files.length === 0)
  );
  const [activeFile, setActiveFile] = useState<string | undefined>(files?.[0]?.name);

  // Configure Monaco with TypeScript types
  const configureMonaco = (monaco: typeof Monaco) => {
    // Set default TypeScript compiler options
    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false,
    });

    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      module: monaco.languages.typescript.ModuleKind.ESNext,
      noEmit: false,
      esModuleInterop: true,
      jsx: monaco.languages.typescript.JsxEmit.React,
      allowJs: true,
      typeRoots: ["node_modules/@types"],
      out: "exports.ts",
      outFile: "exports.ts",
    });

    // Add SDK type definitions
    monaco.languages.typescript.typescriptDefaults.addExtraLib(
      `
      declare module '@stunt-double/sdk' {
        // Core Types
        export type Category = 'browser' | 'ai' | 'identity' | 'notification' | 'human' | 'retrieval' | 'data' | 'utility';
        
        // Base Action Types
        export interface ActionOptionsBase {
          name: string;
          target?: string;
          value?: string | null;
          timeout?: number;
          description?: string;
          options?: Record<string, any>;
          order_index?: number;
        }

        // Browser Action Types
        export interface BrowserAction extends ActionOptionsBase {
          tool: 'browser';
          method: ${methods};
          selector?: string;
          iframe?: string;
          waitUntil?: 'load' | 'domcontentloaded' | 'networkidle0' | 'networkidle2';
          validation?: {
            method: string;
            selector?: string;
            expectedValue?: any;
          };
          fallback?: {
            type: BrowserInstructionType;
            target?: string;
            value?: string;
          };
        }

        // AI Action Types
        export interface AIAction extends ActionOptionsBase {
          tool: 'ai';
          method: 'chat' | 'complete' | 'use_function' | 'vision';
          provider: 'anthropic' | 'openai' | 'fireworks' | 'huggingface' | 'chrome' | 'custom';
          model: string;
          messages?: Array<{
            role: 'system' | 'user' | 'assistant' | 'tool';
            content: string;
            name?: string;
            function_call?: {
              name: string;
              arguments: string;
            };
          }>;
          prompt?: string;
          temperature?: number;
          max_tokens?: number;
          image_url?: string;
          functions?: Array<{
            name: string;
            description: string;
            parameters: Record<string, unknown>;
          }>;
          function_call?: 'auto' | 'none' | { name: string };
        }

        // Sequence Types
        export interface SequenceStepOptions {
          retries: number;
          retry_delay: number;
          timeout?: number;
          parallel?: boolean;
          condition?: string;
          error_handling?: 'continue' | 'stop' | 'retry';
        }

        export interface SequenceStep {
          id: string;
          sequence_id: string;
          order_index: number;
          category: Category;
          action: BrowserAction | AIAction | IdentityAction | NotificationAction | HumanAction | RetrievalAction | ContextAction | IntegrationAction | EventAction | ReferenceAction;
          target?: string;
          value?: string;
          options?: SequenceStepOptions;
          correction?: SelfCorrectionOptions;
          evaluation?: EvaluationOptions;
        }
        export type SelfCorrectionOptions = {
          enabled: 'onFailure' | 'onSuccess' | 'always' | 'never';
          customPrompt?: string;
          provideContext?: boolean;
          updateContext?: boolean;
          generateNewAction?: boolean;
          allowedCategories?: Category[];
          abandonSubsequentSteps?: boolean;
          maxIterations?: number;
          timeout?: number;
        }

        export type EvaluationOptions = {
          successCondition?: string;
          failureCondition?: string;
          evaluation: 'vision' | 'text' | 'both';
          continueOnFailure?: boolean;
          continueOnSuccess?: boolean;
          invertSuccessCondition?: boolean;
        }

        export interface Sequence {
          id: string;
          uid: string;
          workspace_id: string;
          version: number;
          name: string;
          description?: string;
          status: 'draft' | 'published' | 'archived';
          created_at: string;
          updated_at: string;
          app_metadata: Record<string, unknown>;
          settings?: {
            browser_width?: number;
            browser_height?: number;
            record_session?: boolean;
            screenshot_on_step?: boolean;
            network_preset?: string;
          };
          error_handling?: {
            retry_attempts?: number;
            retry_delay?: number;
            screenshot_on_error?: boolean;
            continue_on_error?: boolean;
          };
        }

        // Message Types
        export interface Message {
          id?: string;
          role: 'system' | 'user' | 'assistant' | 'tool';
          content: string;
          name?: string;
          function_call?: {
            name: string;
            arguments: string;
          };
        }

        // Identity Action
        export interface IdentityAction extends ActionOptionsBase {
          tool: 'identity';
          method: 'login' | 'logout' | 'verify' | 'register';
          provider?: 'oauth' | 'saml' | 'basic' | 'token';
          credentials?: {
            username?: string;
            password?: string;
            token?: string;
            apiKey?: string;
            clientId?: string;
            clientSecret?: string;
          };
          scope?: string[];
          mfa?: {
            enabled: boolean;
            method: 'totp' | 'sms' | 'email';
            code?: string;
          };
        }

        // Notification Action
        export interface NotificationAction extends ActionOptionsBase {
          tool: 'notification';
          method: 'email' | 'sms' | 'push' | 'webhook';
          template?: string;
          recipients: string[];
          content: {
            subject?: string;
            body: string;
            format?: 'text' | 'html' | 'markdown';
            attachments?: Array<{
              filename: string;
              content: string;
              contentType: string;
            }>;
          };
          priority?: 'low' | 'normal' | 'high' | 'urgent';
          scheduling?: {
            sendAt?: string;
            timezone?: string;
            expireAt?: string;
          };
        }

        // Human Action
        export interface HumanAction extends ActionOptionsBase {
          tool: 'human';
          method: 'verify' | 'approve' | 'review' | 'input' | 'captcha';
          prompt: {
            title: string;
            description: string;
            type: 'text' | 'select' | 'multiselect' | 'file' | 'captcha';
            options?: string[];
            validation?: {
              required: boolean;
              pattern?: string;
              minLength?: number;
              maxLength?: number;
              allowedTypes?: string[];
            };
          };
          timeout: number;
          escalation?: {
            after: number;
            to: string[];
            message: string;
          };
          response?: {
            value: unknown;
            timestamp: string;
            user: string;
          };
        }

        // Retrieval Action
        export interface RetrievalAction extends ActionOptionsBase {
          tool: 'retrieval';
          method: 'search' | 'query' | 'fetch' | 'aggregate';
          query: string;
          index: string;
          filters?: Record<string, unknown>;
          options?: {
            limit?: number;
            offset?: number;
            minScore?: number;
            includeMetadata?: boolean;
            namespace?: string;
          };
        }

        // Context Action
        export interface ContextAction extends ActionOptionsBase {
          tool: 'context';
          method: 'get' | 'set' | 'update' | 'delete';
          key: string;
          scope: 'global' | 'workspace' | 'sequence' | 'run';
          value?: unknown;
          valueType?: 'string' | 'number' | 'boolean' | 'object' | 'array';
          path?: string[];
          options?: {
            merge?: boolean;
            upsert?: boolean;
            ttl?: number;
            version?: number;
            strict?: boolean;
          };
        }

        // Integration Action
        export interface IntegrationAction extends ActionOptionsBase {
          tool: 'integration';
          method: 'connect' | 'disconnect' | 'sync' | 'webhook';
          service: string;
          config?: Record<string, unknown>;
          credentials?: {
            type: 'oauth' | 'apikey' | 'basic';
            [key: string]: unknown;
          };
          options?: {
            timeout?: number;
            retry?: boolean;
            maxRetries?: number;
            backoff?: number;
          };
        }

        // Event Action
        export interface EventAction extends ActionOptionsBase {
          tool: 'event';
          method: 'emit' | 'listen' | 'subscribe' | 'unsubscribe';
          eventType: string;
          payload?: Record<string, unknown>;
          options?: {
            timeout?: number;
            parallel?: boolean;
            condition?: string;
            maxParallel?: number;
          };
        }

        // Reference Action
        export interface ReferenceAction extends ActionOptionsBase {
          tool: 'reference';
          method: 'get' | 'set' | 'link' | 'unlink';
          referenceId: string;
          options?: {
            version?: string | number;
            includeMetadata?: boolean;
            cascade?: boolean;
          };
        }
      }
      `,
      "file:///node_modules/@stunt-double/sdk/index.d.ts"
    );
  };

  // Only set initial value once on mount or when initialValue changes from undefined to a value
  useEffect(() => {
    if (!editorRef.current || !initialValue) return;

    const editor = editorRef.current;

    if (files && files.length > 0) {
      const currentFile = files.find((f) => f.name === activeFile);
      if (currentFile) {
        editor.setValue(currentFile.content);
        setIsPlaceholder(false);
      }
    } else {
      editor.setValue(initialValue);
      setIsPlaceholder(false);
    }
  }, [initialValue]); // Only re-run if initialValue changes from undefined to a value

  // Update the evaluateExports function
  const evaluateExports = async (code: string) => {
    if (!onExports) return;
    onExports(code);
  };

  // Update handleContentChange to use the new evaluation
  const handleContentChange = async (editor: Monaco.editor.IStandaloneCodeEditor) => {
    const newValue = editor.getValue();
    onChange(newValue, activeFile);
    setIsEdited(true);
    setIsPlaceholder(!newValue);
    await evaluateExports(newValue);
  };

  const handleEditorDidMount: OnMount = (editor, monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;
    configureMonaco(monaco);

    // Create a model with TypeScript
    const oldModel = editor.getModel();
    if (oldModel) oldModel.dispose();

    const uri = monaco.Uri.parse("file:///main.tsx");
    const model = monaco.editor.createModel(initialValue || "", "typescript", uri);
    editor.setModel(model);

    // Set initial content
    if (files && files.length > 0) {
      const currentFile = files.find((f) => f.name === activeFile);
      if (currentFile) {
        editor.setValue(currentFile.content);
        onChange(currentFile.content, currentFile.name);
        evaluateExports(currentFile.content);
      }
    } else if (initialValue) {
      editor.setValue(initialValue);
      onChange(initialValue);
      evaluateExports(initialValue);
    }
    setIsPlaceholder(!initialValue && (!files || files.length === 0));

    // Add event listener for content changes
    editor.onDidChangeModelContent(() => handleContentChange(editor));

    // Add save command
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      handleSave();
    });
  };

  const handleSave = async () => {
    if (!editorRef.current) return;
    const editorValue = editorRef.current.getValue();
    onSave(editorValue, activeFile);
    await evaluateExports(editorValue);
    setIsEdited(false);
  };

  const handleFileChange = (fileName: string) => {
    if (!editorRef.current || !files || !monacoRef.current) return;
    const file = files.find((f) => f.name === fileName);
    if (file) {
      setActiveFile(fileName);

      // Create new model for the file
      const monaco = monacoRef.current;
      const oldModel = editorRef.current.getModel();
      if (oldModel) oldModel.dispose();

      const uri = monaco.Uri.parse(`file:///${fileName}`);
      const model = monaco.editor.createModel(file.content, "typescript", uri);
      editorRef.current.setModel(model);

      onChange(file.content, fileName);
      setIsEdited(false);
      setIsPlaceholder(false);
    }
  };

  const editor = (
    <div className={cn("flex flex-col space-y-1", isPlaceholder ? "grayscale" : "")}>
      <div className="flex items-center justify-between">
        <div className="align-end flex flex-col gap-1">
          <label className="text-sm font-medium">{label}</label>
          {helpText && <p className="text-tiny text-default-400">{helpText}</p>}
        </div>
        <div className="flex items-center">
          {files && files.length > 0 && (
            <div className="flex gap-2">
              {files.map((file) => (
                <Button
                  size="sm"
                  variant="light"
                  key={file.name}
                  onClick={() => handleFileChange(file.name)}
                  className={cn(
                    "rounded px-2 py-1 text-xs",
                    activeFile === file.name ? "bg-default-100" : "hover:bg-default-50"
                  )}
                >
                  {file.name}
                </Button>
              ))}
            </div>
          )}
          <Button
            size="sm"
            variant="light"
            onClick={handleSave}
            isDisabled={!isEdited}
            className={cn(
              "rounded px-2 py-1 text-xs",
              isEdited
                ? "bg-primary text-primary-foreground hover:bg-primary/90"
                : "hover:bg-default-200"
            )}
          >
            {isEdited ? "Save" : "Saved"}
          </Button>
        </div>
      </div>

      <Editor
        className={cn("mt-2", isPlaceholder ? "filter-grayscale" : "")}
        height={height}
        defaultLanguage="typescript"
        defaultValue={initialValue || placeholder}
        language="typescript"
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          fontSize: 14,
          lineNumbers: "on",
          roundedSelection: false,
          automaticLayout: true,
          cursorStyle: "line",
          quickSuggestions: true,
          tabCompletion: "on",

          scrollbar: {
            vertical: "auto",
            horizontal: "auto",
            useShadows: false,
            verticalHasArrows: false,
            horizontalHasArrows: false,
            verticalScrollbarSize: 10,
            horizontalScrollbarSize: 10,
            alwaysConsumeMouseWheel: false,
          },
          overviewRulerLanes: 0,
          overviewRulerBorder: false,
          readOnly: false,
        }}
        onMount={handleEditorDidMount}
      />
    </div>
  );

  return (
    <div className="rounded-lg" style={{ pointerEvents: "auto" }}>
      {tooltipContent ? <Tooltip content={tooltipContent}>{editor}</Tooltip> : editor}
    </div>
  );
}
