import { SupabaseClient } from "@supabase/supabase-js";
import type { Database } from "../types/supabase";
import { StuntDoubleAI } from "./ai";
import { Storage } from "./storage";
import { Transform } from "./transform";
import { System } from "./system";
import { Config } from "./config";
import { Perform } from "./perform";
import { DatabaseService } from "./database";
import type { AIProvider } from "./sequence/consts";
import { Model } from "./ai/models";

// Configuration interface
export interface SDKConfig {
  supabase: SupabaseClient<Database>;
  ai?: {
    provider?: AIProvider;
    anthropicApiKey?: string;
    groqApiKey?: string;
    xaiApiKey?: string;
    mistralApiKey?: string;
    cohereApiKey?: string;
    openaiApiKey?: string;
    fireworksApiKey?: string;
    defaultModel?: Model;
  };
  storage?: {
    bucket?: string;
    region?: string;
  };
  system?: {
    environment?: "development" | "staging" | "production";
    debug?: boolean;
  };
  browserbase?: {
    apiKey: string;
  };
}

// Core interfaces
export interface IStuntDouble {
  readonly db: DatabaseService;
  readonly ai: StuntDoubleAI;
  readonly storage: Storage;
  readonly transform: Transform;
  readonly system: System;
  readonly config: Config;
  readonly perform: Perform;
}

// Export instruction category enum
//
export enum InstructionCategoryDeprecated {
  SYSTEM = "system",
  USER = "user",
  ASSISTANT = "assistant",
  FUNCTION = "function",
}

export class StuntDouble implements IStuntDouble {
  private static instance: StuntDouble | null = null;
  private readonly supabase: SupabaseClient<Database>;

  // Services
  public readonly db: DatabaseService;
  public readonly ai: StuntDoubleAI;
  public readonly storage: Storage;
  public readonly transform: Transform;
  public readonly system: System;
  public readonly config: Config;
  public readonly perform: Perform;

  private constructor(config: SDKConfig) {
    this.supabase = config.supabase;

    // Initialize core services first
    this.db = new DatabaseService(this.supabase);
    this.ai = new StuntDoubleAI({
      apiKey: config.ai?.[`${config.ai?.provider || "anthropic"}ApiKey`],
      defaultModel: config.ai?.defaultModel as Model,
      provider: config.ai?.provider || "anthropic",
      options: {},
      anthropicApiKey: config.ai?.anthropicApiKey,
      openaiApiKey: config.ai?.openaiApiKey,
      xaiApiKey: config.ai?.xaiApiKey,
      mistralApiKey: config.ai?.mistralApiKey,
      groqApiKey: config.ai?.groqApiKey,
      fireworksApiKey: config.ai?.fireworksApiKey,
      cohereApiKey: config.ai?.cohereApiKey,
      supabase: this.supabase,
    });
    this.storage = new Storage(this.supabase);
    this.transform = new Transform();
    this.system = new System();
    this.config = new Config(config);

    // Initialize perform last and pass the instance
    this.perform = new Perform(this);
  }

  public static getInstance(): StuntDouble | null {
    return StuntDouble.instance;
  }

  private static createInstance(config: SDKConfig): StuntDouble {
    if (!StuntDouble.instance) {
      StuntDouble.instance = new StuntDouble(config);
    }
    return StuntDouble.instance;
  }
}

// Factory function to create StuntDouble instance
export function createStuntDouble(config: SDKConfig): IStuntDouble {
  return StuntDouble["createInstance"](config);
}

// Export singleton instance getter
export function getStuntDouble(): IStuntDouble {
  const instance = StuntDouble.getInstance();
  if (!instance) {
    throw new Error("StuntDouble SDK not initialized. Call createStuntDouble() first");
  }
  return instance;
}

// Export types
export type StuntDoubleSDK = IStuntDouble;
export type { AIProvider };
