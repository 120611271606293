import React from "react";

export function IsometricDataroom({
  height = 64,
  width = 64,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.1282 24.8584L32.2517 13.9455V10.0443L51.1282 20.9591V24.8584ZM28.3757 11.9939L31.7477 10.0443V31.872L30.5634 32.5567L28.3757 33.8217L28.3757 11.9939ZM20.6222 16.4775L23.9942 14.5278V36.3576L20.6222 38.3072V16.4775ZM16.2429 19.0094V40.8392L12.8708 42.7888V20.959L16.2429 19.0094ZM35.3711 34.2601L16.4947 45.175L13.1227 43.2254L16.6226 41.201H16.6206L21.2261 38.5392L24.3719 36.7213L31.9996 32.3105L34.3112 33.6463L35.3711 34.2601ZM24.2457 49.6564L20.8717 47.7068L39.7502 36.7899L43.1222 38.7395L24.2457 49.6564ZM31.9993 54.14L28.6253 52.1903L47.5037 41.2754L49.2448 42.2827L50.8757 43.2251L31.9993 54.14ZM51.1275 38.8872V42.7885L43.7537 38.5253L41.5326 37.2406L32.2505 31.8738V27.9725L51.1275 38.8872ZM51.1275 29.9239V33.8252L32.2511 22.9083V19.007L51.1275 29.9239Z"
        fill={"#eeeeee"}
      />
    </svg>
  );
}
