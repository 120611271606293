// import sharp from 'sharp';
import { encode, decode } from 'base64-arraybuffer';
// import { Buffer } from 'buffer';

export class Transform {
  // Image transformations
  // images = {
  //   resize: async (input: Buffer, options: sharp.ResizeOptions) => {
  //     return sharp(input).resize(options).toBuffer();
  //   },
  //   convert: async (
  //     input: Buffer,
  //     format: keyof sharp.FormatEnum,
  //     options?: sharp.OutputOptions,
  //   ) => {
  //     return sharp(input)[format](options).toBuffer();
  //   },
  //   compress: async (input: Buffer, quality: number) => {
  //     return sharp(input).jpeg({ quality }).toBuffer();
  //   },
  //   metadata: async (input: Buffer) => {
  //     return sharp(input).metadata();
  //   },
  // };
  // // Base64 conversions
  // base64 = {
  //   toBuffer: (base64: string) => decode(base64),
  //   fromBuffer: (buffer: Buffer) => encode(buffer),
  // };
  // // Data transformations
  // data = {
  //   sanitize: <T>(input: T, schema: any): T => {
  //     return schema.parse(input);
  //   },
  //   serialize: (data: any): string => {
  //     return JSON.stringify(data);
  //   },
  //   deserialize: <T>(input: string): T => {
  //     return JSON.parse(input);
  //   },
  // };
}
