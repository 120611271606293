import React from "react";

export function IsometricLock() {
  return (
    <svg width="46" height="46" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_984_138)">
        <path
          d="M14.3615 21.3104V23.1804V23.1792C14.3965 23.6217 14.6302 24.0242 14.9965 24.2742C15.0503 24.3104 15.1127 24.3304 15.1778 24.3329C15.2078 24.2792 15.2203 24.2179 15.2153 24.1567V22.0467L15.599 22.0167C16.0153 21.9829 16.0627 21.4654 16.0627 21.2417L16.0615 21.2429C15.9927 20.2042 15.444 19.2567 14.5753 18.6829C14.409 18.5792 14.2202 18.5179 14.024 18.5054C13.944 18.5042 13.8665 18.5254 13.7965 18.5642C13.584 18.7392 13.4777 19.0117 13.5152 19.2842C13.5527 19.9854 13.8165 20.6554 14.2677 21.1917L14.3615 21.3104Z"
          fill="#FFC72C"
        />
        <path
          d="M21.5351 15.4315C21.3251 15.1378 21.1101 14.8553 20.8913 14.5828L20.8926 9.10023C20.8926 6.01524 18.7063 2.24647 16.0199 0.698952C14.6312 -0.103544 13.3062 -0.221048 12.29 0.365192C11.2813 0.947688 10.7263 2.1377 10.7263 3.71879V11.3438C10.5588 11.36 10.3913 11.3888 10.2263 11.4275C8.30252 11.8825 7.15625 13.8112 7.15625 16.5849C7.15625 21.5449 10.6724 27.6125 14.9962 30.1088C17.0212 31.2788 18.9038 31.4813 20.2887 30.6775C21.6612 29.8863 22.4187 28.185 22.4187 25.8862C22.4187 22.9849 21.1812 19.5326 19.1051 16.6487H19.1064C18.6051 15.945 18.0514 15.2787 17.4514 14.6562C16.6051 13.7612 15.6376 12.9887 14.5751 12.3624C13.7188 11.8399 12.7651 11.4975 11.7714 11.3562C11.7039 11.3475 11.6364 11.34 11.5689 11.335V3.71864C11.5689 2.4524 11.9751 1.51988 12.7114 1.09489C13.4563 0.664876 14.4826 0.783641 15.6001 1.42865C18.0538 2.84241 20.05 6.28369 20.05 9.10001V13.6264L20.0488 13.6252C19.9938 13.5614 19.935 13.5027 19.88 13.4439C19.615 13.1702 19.3875 12.9514 19.1725 12.7577H19.1738C18.5113 12.1452 17.7838 11.6052 17.005 11.1502C16.1488 10.6277 15.1938 10.2852 14.2013 10.1439C13.6838 10.0714 13.1588 10.0952 12.6513 10.2152C12.375 10.2814 12.11 10.3839 11.86 10.5177H11.8725C12.9775 10.6764 14.0412 11.0552 14.9963 11.6339C16.1262 12.2989 17.1563 13.1202 18.0575 14.0715C18.6862 14.7227 19.2662 15.4189 19.7925 16.1564C21.965 19.1752 23.2626 22.8128 23.2626 25.8864H23.2613C23.2988 27.1814 23.0063 28.4651 22.4114 29.6163L22.5076 29.57C23.9938 28.8463 24.8439 27.06 24.8439 24.6689C24.8451 21.7727 23.6076 18.3202 21.5351 15.4315ZM14.9962 15.524C17.6824 17.074 19.8723 20.8466 19.8723 23.9279C19.8723 25.5066 19.3161 26.6941 18.3061 27.2792V27.2805C17.8698 27.5255 17.3786 27.6517 16.8786 27.6467C16.0624 27.6205 15.2686 27.3792 14.5749 26.948C11.8886 25.398 9.70351 21.6305 9.70351 18.5441C9.70351 16.9691 10.2598 15.7779 11.2698 15.1966C12.2885 14.6066 13.611 14.724 14.9962 15.524Z"
          fill="#FFC72C"
        />
        <path
          d="M10.546 18.544C10.546 21.3603 12.5423 24.8052 14.9959 26.2202C16.1122 26.864 17.1347 26.9827 17.8847 26.5489C18.6209 26.124 19.0297 25.1927 19.0297 23.9302C19.0297 21.1139 17.0297 17.6739 14.5746 16.254V16.2528C14.0096 15.899 13.3634 15.6978 12.6971 15.6678C12.3446 15.6615 11.9971 15.7503 11.6909 15.924C10.9496 16.3503 10.546 17.2803 10.546 18.544ZM13.371 17.8366C13.8935 17.5741 14.5173 17.6191 14.996 17.9541C16.126 18.6778 16.8373 19.9028 16.9035 21.2427C16.9648 21.8765 16.6272 22.4827 16.0573 22.7664V24.1565C16.0798 24.519 15.9048 24.8652 15.5985 25.0615C15.4673 25.1365 15.3198 25.1752 15.1685 25.1752C14.9585 25.1727 14.7535 25.1127 14.5747 25.0027C13.9472 24.6027 13.5535 23.9227 13.5185 23.179V21.6002C12.9997 20.9365 12.7035 20.1264 12.6722 19.284C12.6222 18.7102 12.891 18.1541 13.371 17.8366Z"
          fill="#FFC72C"
        />
      </g>
      <defs>
        <clipPath id="clip0_984_138">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
